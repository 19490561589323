import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../api/AxiosHttp";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";

import London from "../../images/proactive-location-london.svg";
import NewYork from "../../images/proactive-location-newyork.svg";
import Vancouver from "../../images/proactive-location-vancouver.svg";
import Toronto from "../../images/proactive-location-toronto.svg";
import Sydney from "../../images/proactive-location-sydney.svg";
import Perth from "../../images/proactive-location-perth.svg";

const Contact = () => {
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);

  const [submitloading, setSubmitloading] = useState(false);

  useEffect(() => {
    logActivity();
  }, []);

  const logActivity = async () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_contact_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_contact",
        type: "company",
      });
      sessionStorage.setItem("view_contact_" + companyData.company_id, true);
    }
  };

  const logContactClicks = async (email) => {
    if (userData.role === process.env.REACT_APP_CLIENT) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        user_id: userData.id,
        model_id: userData.id,
        action: "clicked_email",
        type: "user",
        details: email,
      });
    }
  };

  const handleSubmit = () => {};

  return (
    <>
      <BaseTitle heading="Contact" />

      {/* <BaseCard className="grid gap-12 md:grid-cols-2">
        <div>
          <p className="mb-4 text-xl">
            If you need further information about Proactive Analytics please do not
            hesitate to contact your nearest office.
          </p>

          <p>
            Please leave a voicemail if we are not free to take your call, or
            complete the form and we will get back to you as soon as
            possible.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid gap-5 md:grid-cols-2">
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="firstname"
              >
                First Name
              </label>
              <input
                id="firstname"
                name="firstname"
                className="form-input w-full"
                type="text"
                required
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="lastname"
              >
                Last Name
              </label>
              <input
                id="lastname"
                name="lastname"
                className="form-input w-full"
                type="text"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                className="form-input w-full"
                type="email"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="phone">
                Phone
              </label>
              <input
                id="phone"
                name="phone"
                className="form-input w-full"
                type="text"
              />
            </div>
            <div className="col-span-2">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="message"
              >
                Message
              </label>
              <div className="relative">
                <textarea
                  id="message"
                  className="form-textarea w-full"
                  name="message"
                  rows="7"
                />
              </div>
            </div>
          </div>
          <BaseButton
            className="mt-6"
            label="Submit"
            type="submit"
            loading={submitloading}
          />
        </form>
      </BaseCard> */}

      <BaseCard className="">
        <h4 className="text-xl mb-3 uppercase text-violet font-bold w-fit">
          Key Contacts
        </h4>
        <p>
          <ul className="list-image-custom pl-6">
            <li className="mb-1">
              Technical Support Team (Global) -{" "}
              <a
                className="text-primary underline"
                href="mailto:protech@proactiveinvestors.com"
                onClick={() =>
                  logContactClicks("protech@proactiveinvestors.com")
                }
              >
                protech@proactiveinvestors.com
              </a>
            </li>
            <li className="mb-1">
              Chris Hogg, Chief Revenue Officer (Canada) -{" "}
              <a
                className="text-primary underline"
                href="mailto:chris@proactiveinvestors.com"
                onClick={() => logContactClicks("chris@proactiveinvestors.com")}
              >
                chris@proactiveinvestors.com
              </a>
            </li>
            <li className="mb-1">
              Ruth Gemmell, Communications Director (UK) -{" "}
              <a
                className="text-primary underline"
                href="mailto:ruth@proactiveinvestors.com"
                onClick={() => logContactClicks("ruth@proactiveinvestors.com")}
              >
                ruth@proactiveinvestors.com
              </a>
            </li>
            <li className="mb-1">
              Ian Lyall, Managing Editor (UK) -{" "}
              <a
                className="text-primary underline"
                href="mailto:ian.lyall@proactiveinvestors.com"
                onClick={() =>
                  logContactClicks("ian.lyall@proactiveinvestors.com")
                }
              >
                ian.lyall@proactiveinvestors.com
              </a>
            </li>
            <li className="mb-1">
              Nick Shallcross, Social Media & YouTube Journalist -{" "}
              <a
                className="text-primary underline"
                href="mailto:n.shallcross@proactiveinvestors.com"
                onClick={() =>
                  logContactClicks("n.shallcross@proactiveinvestors.com")
                }
              >
                n.shallcross@proactiveinvestors.com
              </a>
            </li>
          </ul>
        </p>
      </BaseCard>

      <BaseCard>
        <div className="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white">
          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b">
            <span className="p-5 h-[140px] flex items-end">
              <img src={London} width="75" />
            </span>
            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              London
            </h4>
            <p className="mt-2">
              2nd Floor, 35 Great St. Helen’s Street <br />
              London, EC3A 6AP
            </p>
            <p className="mt-2">Tel: +44 (0)207 989 0813</p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b">
            <span className="p-5 h-[140px] flex items-end">
              <img src={NewYork} width="75" />
            </span>
            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              New York
            </h4>
            <p className="mt-2">
              767 Third Avenue, Floor 17 <br />
              New York NY 10017
            </p>
            <p className="mt-2">Tel: +1 347 449 0879</p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group   md:lg:xl:border-b">
            <span className="p-5 h-[140px] flex items-end">
              <img src={Vancouver} width="75" />
            </span>
            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              Vancouver
            </h4>
            <p className="mt-2">
              Suite 965 1055 West Georgia Street <br />
              Vancouver V6E 3P3
            </p>
            <p className="mt-2">Tel: +1 604-688-8158</p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group   md:lg:xl:border-r">
            <span className="p-5 h-[140px] flex items-end">
              <img src={Toronto} width="75" />
            </span>
            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              Toronto
            </h4>
            <p className="mt-2">
              Suite 7210 100 King Street West <br />
              Toronto Ontario M5X 1E1
            </p>
            <p className="mt-2">Tel: +1 604 688 8158</p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r">
            <span className="p-5 h-[140px] flex items-end">
              <img src={Sydney} width="75" height="100" />
            </span>
            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              Sydney
            </h4>
            <p className="mt-2">
              213 Edgeware Road <br />
              Newtown NSW 2042
            </p>
            <p className="mt-2">Tel: +61 (0) 431 597 771</p>
          </div>

          <div className="p-10 flex flex-col items-center text-center group">
            <span className="p-5 h-[140px] flex items-end">
              <img src={Perth} width="75" height="100" />
            </span>

            <h4 className="text-xl mt-3 uppercase text-violet font-bold w-fit">
              Perth
            </h4>
            <p className="mt-2">
              Level 2 East The Wentworth Building 300 Murray Street Off Raine
              Lane <br />
              Perth WA 6000
            </p>
            <p className="mt-2">Tel: +61 (0) 2 9280 0700</p>
          </div>
        </div>
      </BaseCard>
    </>
  );
};

export default Contact;
